import React, { useState, useEffect } from 'react';
import './contact.scss'
import { urlFor, client } from '../../client';


const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', company: '', phone: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { username, email, company, phone, message } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = () => {
        setLoading(true);
    
        const contact = {
          _type: 'contact',
          name: formData.username,
          email: formData.email,
          company: formData.company,
          phone: formData.phone,
          message: formData.message,
        };
    
        client.create(contact)
          .then(() => {
            setLoading(false);
            setIsFormSubmitted(true);
          })
          .catch((err) => console.log(err));
      };

  return (
    <div className='contact-all'>
    <div className='contact'>
            <div className='contact-1'>
                <h1>Contact Us</h1>
                <h3>78 Antonio Street, Liverpool, L20 2EU, UK</h3>
                <h3>manager@amazingcareltd.com</h3>
                <h3>07403397114</h3>
            </div>
            {!isFormSubmitted ? (
            <div className='contact-2'>
                <form class="contact-form row">
                    <div class="form-field col-lg-6">
                        <input name="username" class="input-text js-input" type="text" required value={username} onChange={handleChangeInput}/>
                        <label class="" for="name">Enter your Name</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="email" class="input-text js-input" type="email" required value={email} onChange={handleChangeInput}/>
                        <label class="" for="email">Enter your Email</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name='company' class="input-text js-input" type="text" required value={company} onChange={handleChangeInput}/>
                        <label class="" for="company">Company Name</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="phone" class="input-text js-input" type="text" required value={phone} onChange={handleChangeInput} />
                        <label class="" for="phone">Contact Number</label>
                    </div>
                    <div class="form-field col-lg-12">
                        <input name="message" class="input-text js-input" type="text" required value={message} onChange={handleChangeInput}/>
                        <label class="" for="message">Message</label>
                    </div>
                    <div class="form-field col-lg-12">
                        <button type="button" className="submit-btn" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
                    </div>
                </form>
            </div>
            ) : (
              <div>
                  <h3 className="home">
                      Thank you for getting in touch!
                  </h3>
              </div>
          )}
          </div>
          </div>
  )
}

export default Contact;