import React, { useState, useEffect } from 'react';
import './employment.scss'
import { urlFor, client } from '../../client';

const Employment = () => {
  const [formData, setFormData] = useState({ name: '', title: '', address: '', postcode: '', pob: '', birth: '', dbs: '', drive: '', religion: '', email: '', experience: '', phone: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { username, experience, email, phone, message, address, title, postcode, pob, birth, drive, religion, dbs } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = () => {
        setLoading(true);
    
        const application = {
          _type: 'application',
          name: formData.username,
          email: formData.email,
          experience: formData.experience,
          phone: formData.phone,
          message: formData.message,
          address: formData.address,
          title: formData.title,
          pob: formData.pob,
          postcode: formData.postcode,
          birth: formData.birth,
          drive: formData.drive,
          religion: formData.religion,
          dbs: formData.dbs
        };
    
        client.create(application)
          .then(() => {
            setLoading(false);
            setIsFormSubmitted(true);
          })
          .catch((err) => console.log(err));
      };
  return (
    <div className="employment">
      <div className='employment-header'>
        <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685447679/pexels-aleksandar-pasaric-1323712_tasakf.jpg' alt='image' />
        <div className='employment-header_text'>
                <h1>Career</h1>
                <h3>Be A Part Of Our Growing Team</h3>
        </div>
      </div>

      <div className='employment-roles'>
        <div className='role-1'>
          <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685474569/pexels-kampus-production-7551684_xa7sm2.jpg' alt='image' />
          <div className='role-text'>
              <h1>Doctors</h1>
              <h2>Contact Us</h2>
          </div>
        </div>
        <div className='role-2'>
          <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685474569/pexels-rdne-stock-project-6646990_kyx1sk.jpg' alt='image' />
          <div className='role-text'>
              <h1>Nurses</h1>
              <h2>Contact Us</h2>
          </div>
        </div>
        <div className='role-3'>
          <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685474565/pexels-matthias-zomer-339620_lrp3xr.jpg' alt='image' />
          <div className='role-text'>
              <h1>care giver</h1>
              <h2>Contact Us</h2>
          </div>
        </div>
        <div className='role-4'>
          <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685474566/pexels-vlada-karpovich-5790702_fywt9a.jpg' alt='image' />
          <div className='role-text'>
              <h1>house cleaners and keepers</h1>
              <h2>Contact Us</h2>
          </div>
        </div>
      </div>
      
      <div className='employment-apply'>
        <div className='employment-apply_1'>
          <h1>Join us</h1>
          <h5>Apply for a job</h5>
          {!isFormSubmitted ? (
          <form class="employment-form row">
                    <div class="form-field col-lg-6">
                        <select name="title" required className="select-text" value={title} onChange={handleChangeInput}>
                          <option>Select Title</option>
                          <option>Mr</option>
                          <option>Mrs</option>
                          <option>Miss</option>
                          <option>Others</option>
                        </select>
                        <label class="" for="title">Title</label>
                    </div>
                    <div class="form-field col-lg-6">
                        <input name="username" class="input-text js-input" type="text" required value={username} onChange={handleChangeInput}/>
                        <label class="" for="name">Enter your Name</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="email" class="input-text js-input" type="email" required value={email} onChange={handleChangeInput} />
                        <label class="" for="email">Enter your Email</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="phone" class="input-text js-input" type="text" required value={phone} onChange={handleChangeInput}/>
                        <label class="" for="phone">Contact Number</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="address" class="input-text js-input" type="text" required value={address} onChange={handleChangeInput}/>
                        <label class="" for="address">Home Address</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="postcode" class="input-text js-input" type="tel" required value={postcode} onChange={handleChangeInput}/>
                        <label class="" for="number">Postcode</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="pob" class="input-text js-input" type="text" required value={pob} onChange={handleChangeInput}/>
                        <label class="" for="address">Country of birth</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="birth" class="input-date" type="date" required value={birth} onChange={handleChangeInput} />
                        <label class="" for="birthday">Date of birth</label>
                    </div>
                    <div class="form-field col-lg-6">
                        <select name="experience" required className="select-text" value={experience} onChange={handleChangeInput}>
                          <option value="">Select an option</option>
                          <option>Less than 1 year</option>
                          <option>1 - 2 Years</option>
                          <option>3 - 5 Years</option>
                          <option>Above 5 Years</option>
                        </select>
                        <label class="" for="title">Years of experience</label>
                    </div>
                    <div class="form-field col-lg-6">
                        <select name="religion" required className="select-text" value={religion} onChange={handleChangeInput}>
                          <option value="">Select an option</option>
                          <option>Agnostic</option>
                          <option>Atheist</option>
                          <option>Christianity</option>
                          <option>Hinduism</option>
                          <option>Islam</option>
                          <option>Nonreligious</option>
                          <option>Others</option>
                        </select>
                        <label class="" for="title">Religion or Belief</label>
                    </div>
                    <div class="form-field col-lg-6">
                        <select name="dbs" required className="select-text" value={dbs} onChange={handleChangeInput}>
                          <option>Select an option</option>
                          <option>No</option>
                          <option>Yes</option>
                        </select>
                        <label class="" for="title">Do you have a valid Enhanced DBS check</label>
                    </div>
                    <div class="form-field col-lg-6">
                        <select name="drive" required className="select-text" value={drive} onChange={handleChangeInput}>
                          <option>Select an option</option>
                          <option>No</option>
                          <option>Yes</option>
                        </select>
                        <label class="" for="title">Do you drive</label>
                    </div>
                    
                    <div class="form-field col-lg-12">
                        <input name="message" class="input-text js-input" type="text" required value={message} onChange={handleChangeInput}/>
                        <label class="" for="message">Additional Info</label>
                    </div>
                    <div class="form-field col-lg-12">
                        <button type="button" className="submit-btn" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
                    </div>
                </form>
                 ) : (
                  <div>
                      <h3 className="home">
                          Your Application has been sent!
                      </h3>
                  </div>
              )}
        </div>
        <div className='employment-apply_2'>
          <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685474187/pexels-pixabay-45842_u5tzyl.jpg' alt='image' />
        </div>
      </div>
    </div>
  )
}

export default Employment;