import React from 'react'
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Footer, Navbar } from './components';
import { Home, About, Employment, Services, Contact } from './container';

function App() {
  return (
    <div className="App">
      <Router>
       <Navbar />
        <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about-us" component={About} />
        <Route path="/employment" component={Employment} />
        <Route path="/services" component={Services} />
        <Route path="/contact" component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
