import React, {useState} from 'react'
import './navbar.scss'
import { Link } from "react-router-dom";
import { HiMenu, HiX } from 'react-icons/hi'
import { motion } from 'framer-motion'

const Navbar = () => {
    const [toggle, setToggle] = useState(false);
  return (
    <nav className='app__navbar'>
        <div className='app__navbar-logo'>
            <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685371630/Picture_1_fpzxnp.png' alt='logo' />
        </div>
        <ul className="app__navbar-links">
          <Link to='/'><li className="app__flex p-text">HOME</li></Link>
          <Link to='/about-us'><li className="app__flex p-text">ABOUT US</li></Link>
          <Link to='/services'><li className="app__flex p-text">SERVICES</li></Link>
          <Link to='/employment'><li className="app__flex p-text">EMPLOYMENT</li></Link>
          <Link to='/contact'><li className="app__flex p-text">CONTACT</li></Link>
        {/* {['home', 'about us', 'services', 'employment', 'contact'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))} */}
      </ul>

        <div className='app__navbar-menu'>
        <HiMenu onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {/* <Link to='/'><li>Home</li></Link>
              <Link to='/about-us'><li>About Us</li></Link>
              <Link to='/services'><li>Services</li></Link>
              <Link to='/employment'><li>Employment</li></Link>
              <Link to='/contact'><li>Contact</li></Link> */}
              <li><Link to='/' onClick={() => setToggle(false)}>Home</Link></li>
              <li><Link to='/about-us' onClick={() => setToggle(false)}>About Us</Link></li>
              <li><Link to='/services' onClick={() => setToggle(false)}>Services</Link></li>
              <li><Link to='/employment' onClick={() => setToggle(false)}>Employment</Link></li>
              <li><Link to='/contact' onClick={() => setToggle(false)}>Contact</Link></li>
            </ul>
            {/* <ul>
              {['home', 'about us', 'services', 'employment', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul> */}
          </motion.div>
        )}
        </div>
    </nav>
  )
}

export default Navbar