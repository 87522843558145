import React, { useState, useEffect } from 'react'
import './services.scss'
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client';
import { Link } from 'react-router-dom';


const Services = () => {
  const [services, setServices] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const query = '*[_type == "services"]';

    client.fetch(query).then((data) => {
      setServices(data);
      setFilterWork(data);
    });
  }, []);

  return (
    <div className="services">
      <div className='services-header'>
        <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685447651/pexels-pixabay-50570_prtewn.jpg' alt='image' />
        <div className='services-header_text'>
                <h1>our services</h1>
        </div>
      </div>

      <div className='services-container'>
        <div className='services-header'>
          <h1>Our care services are designed</h1>
          <h1>to fit a broad range of care</h1>
        </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="container"
      >
        {filterWork.map((services, index) => (
          <div className="services-container_item ">
            <div className="services-container_img app__flex">
              <img src={urlFor(services.imgUrl)} alt={services.name} />
            </div>

            <div className="services-container_content app__flex">
              <h4 className="bold-text">{services.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>{services.description}</p>
            </div>
          </div>
        ))}
      </motion.div>
      </div>

      <div className='services-second'>
        <h1>How it works</h1>
        <div className='services-second_container'>
          
            <div className='services-second_content'>
              <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685480431/3-Openess_uduqyx.png' alt='photo' />
              <h3>Get in touch</h3>
              <p>Call us or <Link to='/contact'>click here</Link> to request a call back</p>
            </div>
            <div className='services-second_content'>
              <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685480431/3-Openess_uduqyx.png' alt='photo' />
              <h3>Let’s meet</h3>
              <p>Our home care specialist will provide a free consultation to discuss and create your personally tailored care plan</p>
            </div>
            <div className='services-second_content'>
              <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685480431/3-Openess_uduqyx.png' alt='photo' />
              <h3>Care begins</h3>
              <p>Your personal care plan will be delivered by our care specialists.</p>
            </div>
          
        </div>
      </div>
    </div>
  )
}

export default Services;