import React from 'react'
import './about.scss'
import { motion } from 'framer-motion';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const About = () => {
  return (
    <div className='about'>
      <div className='about-header'>
        <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685447648/pexels-gdtography-950241_vjxa3o.jpg' alt='image' />
        <div className='about-header_text'>
                <h1>About us</h1>
        </div>
      </div>

      <div className='about-first'>
        <h1>Our Story</h1>
        <h3>Providing You High Quality Home Care Solutions</h3>
        <p>At Amazing Care, we provide a wide range of home health care services to patients all throughout the Merseyside area. Our staff goes above and beyond to make sure you get the best quality care possible, and are committed to providing you with all the resources you need in order to get back on your feet. </p>
        <p>We are passionate about our professional and personalized care, and work hard to meet, and exceed, your expectations. Contact us if you have any comments or questions, and start your journey back to healthy living with us today.</p>
      </div>

      <div className='about-second'>
        <h1>Mission Statement</h1>
        <h4>The Amazing Care mission statement is simply:</h4>
        <p>“To deliver a care service designed to enhance the quality, enrich the experience and prolong the enjoyment of home-living in our society.”</p>

        <h1>Premium Homecare</h1>
        <p>Amazing Care sets out to deliver a premium homecare experience for those unable to deal with all of the daily tasks that accompany home living, whether that be a result of disability, illness or ageing. By providing assistance with daily routines, Amazing Care aims to allow their clients to continue living comfortably and happily in their home environment for as long as possible.</p>
        <p>Whether it’s a daily visit to help Mum shower, a weekly visit to take Dad out for lunch or a weekday visit to help get your sister up and ready for the day-care centre, Amazing Care will work with you to get it right! Our aim will always be to enhance their everyday care experience.</p>
        <p>In addition to daily care services, Amazing Care are also seeking to extend their service offering by providing a number of enrichment services to clients. The website will be updated and clients notified, when these services become available.</p>

        <h1>Care Services</h1>
        <ul>
          <li>Caring for people with Dementia</li>
          <li>Elderly Care</li>
          <li>Social Trips / Shopping</li>
          <li>Domestic Services / House Help</li>
          <li>Mobilising and Excercise</li>
          <li>Medication Assistance</li>
          <li>Physical Disability Care</li>
          <li>Mental Health Condition</li>
        </ul>
      </div>
    </div>
  )
}

export default About;