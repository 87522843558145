import React, { useState, useEffect } from 'react';
import './home.scss'
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client';

const Home = () => {
    const [features, setfeatures] = useState([]);
    const [formData, setFormData] = useState({ name: '', email: '', company: '', phone: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { username, email, company, phone, message } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = () => {
        setLoading(true);
    
        const contact = {
          _type: 'contact',
          name: formData.username,
          email: formData.email,
          company: formData.company,
          phone: formData.phone,
          message: formData.message,
        };
    
        client.create(contact)
          .then(() => {
            setLoading(false);
            setIsFormSubmitted(true);
          })
          .catch((err) => console.log(err));
      };

    useEffect(() => {
    const query = '*[_type == "features"]';

    client.fetch(query).then((data) => {
      setfeatures(data);
    });
  }, []);

  return (
    <div className="home">
        <div className='home-board'>
            <img src='https://res.cloudinary.com/dsnontai6/image/upload/v1685377831/pexels-kampus-production-7551583_k8dacb.jpg' alt='image' />
            <div className='home-text'>
                <h1>Welcome to</h1>
                <h2>Amazing Care & Support Recruitment Service Limited</h2>
            </div>
        </div>

        <div className='home-about'>
            <h1>Who are we</h1>
            <h3>Highly Skilled Practitioners at Your Service</h3>
            <p>At Amazing Care, we provide reliable and affordable home care services to clients in the Merseyside area. Whether you need daily health related attention or are looking for appointment based care — you name it, we do it! Our certified practitioners work with you and your doctor to develop and manage a personalized home health plan that’s most convenient for you.</p>
        </div>

        <div className='home-services'>
            <h1>What do we do</h1>
            <div className='home-services_container'>
                {features.map((features, index) => (
                <motion.div
                    whileInView={{ opacity: 1 }}
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.5, type: 'tween' }}
                    className="home-services_content"
                    key={features.title + index}
                    >
                    <h2 className="bold-text" style={{ marginTop: 20 }}>{features.title}</h2>
                    <h3 className="bold-text" style={{ marginTop: 20 }}>{features.subTitle}</h3>
                    <p className="p-text" style={{ marginTop: 10 }}>{features.description}</p>
                </motion.div>
            ))}
            </div>
        </div>

        <div className='home-contact'>
            <div className='contact-1'>
                <h1>Contact Us</h1>
                <h3>78 Antonio Street, Liverpool, L20 2EU, UK</h3>
                <h3>manager@amazingcareltd.com</h3>
                <h3>07403397114</h3>
            </div>
            
            {!isFormSubmitted ? (
                <div className='contact-2'>
                <form class="contact-form row">
                    <div class="form-field col-lg-6">
                        <input name="username" class="input-text js-input" type="text" required value={username} onChange={handleChangeInput} />
                        <label class="" for="name">Enter your Name</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="email" class="input-text js-input" type="email" required value={email} onChange={handleChangeInput} />
                        <label class="" for="email">Enter your Email</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="company" class="input-text js-input" type="text" value={company} onChange={handleChangeInput} required />
                        <label class="" for="company">Company Name</label>
                    </div>
                    <div class="form-field col-lg-6 ">
                        <input name="phone" class="input-text js-input" type="text" value={phone} onChange={handleChangeInput} required />
                        <label class="" for="phone">Contact Number</label>
                    </div>
                    <div class="form-field col-lg-12">
                        <input name="message" class="input-text js-input" type="text" value={message} onChange={handleChangeInput} required />
                        <label class="" for="message">Message</label>
                    </div>
                    <div class="form-field col-lg-12">
                        <button type="button" className="submit-btn" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
                    </div>
                    {/* <input class="submit-btn" type="submit" value="Submit" /> */}
                    
                </form>
            </div>
            ) : (
                <div>
                    <h3 className="home">
                        Thank you for getting in touch!
                    </h3>
                </div>
            )}
            
        </div>
        
    </div>
  )
}

export default Home;